import {UserType} from "./user";

export const TeamType = {
    "id": '',
    "teamName": '',
    "teamAvatarUrl": '',
    "teamPassword": '',
    "teamDesc": '',
    "maxNum": '',
    "expireTime": '',
    "teamStatus": '',
    "createTime": '',
    "announce": '',
}


export const TeamListType = {
    "id": '',
    "teamName": '',
    "teamAvatarUrl" : '',
    "teamPassword": '',
    "teamDesc": '',
    "maxNum": '',
    "expireTime": '',
    "teamStatus": '',
    "createTime": '',
    "announce": '',
    "user": '',
    "userSet": ''
}
